/* eslint-disable prettier/prettier */
export const defaultLanguage = "fr";
// export const languages = ["en", "fr"];
export const languages = ["fr"];

export const languageConfig = {
  "en": "🇬🇧",
  "fr": "🇫🇷",
};

export const themeVariables = {
  dark: {
    mainRed: "#E63946",
    gradientSecondary: "#af4261",
    textPrimary: "#f5f5f5",
    textSecondary: "#d8d3d3",
    textTertiary: "#d8d3d3",
    backgroundColor: "#222",
    backgroundColorDarker: "#111",
  },
  light: {
    mainRed: "#E63946",
    gradientSecondary:"#af4261",
    textPrimary: "#222",
    textSecondary: "#7e686a",
    textTertiary: "#747474",
    backgroundColor: "#f7fafc",
    backgroundColorDarker: "#ececec",
  },
};